import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  Rating,
  Grid,
  styled,
  Button,
} from "@mui/material";
import { FaQuoteLeft } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, A11y } from "swiper/modules";

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    ".swiper-button-next": {
      display: "none",
    },
    ".swiper-button-prev": {
      display: "none",
    },
  },
}));
const StyledCard = styled(Card)(({ theme }) => ({
  userSelect: "none",
  variant: "outlined",
  orientation: "horizontal",
  
  borderRadius:8
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  marginBottom: theme.spacing(2),
}));

const ReviewCard = ({ review, isPlaceholder = false }) => {
  return (
    <StyledCard
      sx={{
        opacity: isPlaceholder ? 0.5 : 1,
        backgroundColor: isPlaceholder ? "var(--color-gray)" : "white",
        
      }}
    >
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <StyledAvatar
            src={isPlaceholder ? "" : review.reviewer.profilePhotoUrl}
            alt={
              isPlaceholder ? "Placeholder Avatar" : review.reviewer.displayName
            }
            sx={{ backgroundColor: isPlaceholder ? "#e0e0e0" : "initial" }}
          />
          <Typography variant="h6" component="div" gutterBottom>
            {isPlaceholder
              ? "Fler recensioner kommer snart"
              : review.reviewer.displayName}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {isPlaceholder
              ? "—"
              : new Date(review.createTime).toLocaleDateString()}
          </Typography>
          <Rating value={isPlaceholder ? 0 : review.starRating} readOnly />
          <Box mt={2} mb={2}>
            <FaQuoteLeft
              size={24}
              color={isPlaceholder ? "#e0e0e0" : "#757575"}
            />
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 2,
              textOverflow: "ellipsis",
              maxWidth: 200,
            }}
          >
            {isPlaceholder
              ? "-------"
              : review.comment || "Inga kommentar"}
          </Typography>
        </Box>
        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
          <FcGoogle size={32} />
          <Typography variant="caption" color="text.secondary" ml={1}>
            Google Review
          </Typography>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

const Review = ({ layout = "carousel", reviewsPerPage = 3, reviews }) => {
  return (
    <StyledBox
      position="relative"
      sx={{ paddingBottom: "50px", maxWidth: "100%", overflow: "hidden" }}
    >
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <FcGoogle size={32} />
        <Typography variant="h4" component="h2" ml={2}>
          GOOGLE RECENSIONER
        </Typography>
      </Box>
      <Typography variant="h4" component="h3" mb={3}>
        Våra Nöjda Kundar
      </Typography>

      {layout === "carousel" ? (
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={20}
          slidesPerView={4}
          navigation
          breakpoints={{
            300: {
              slidesPerView: 1,
 
            },
            768: {
              slidesPerView: 2,
    
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          style={{
            padding: "10px 40px", // add padding to swiper to align arrows better
            position: "relative",
          }}
        >
          {/* Render actual reviews */}
          {reviews?.map((review, index) => (
            <SwiperSlide style={{maxWidth:"300px"}} key={`review-${index}`}>
              <ReviewCard key={`review-${index}`} review={review} />
            </SwiperSlide>
          ))}

          {/* Render placeholder cards to fill up the space */}
          {Array.from({ length: 4 }).map((_, index) => (
            <SwiperSlide style={{maxWidth:"300px"}} key={`placeholder-${index}`}>
              <ReviewCard key={`placeholder-${index}`} isPlaceholder={true} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Grid container spacing={3}>
          {reviews.slice(0, reviewsPerPage).map((review) => (
            <Grid item xs={12} sm={6} md={4} key={review.reviewId}>
              <ReviewCard review={review} />
            </Grid>
          ))}
        </Grid>
      )}
      <Box mt={3} textAlign="center">
        <Typography variant="body2" color="text.secondary">
          <Button
            variant="contained"
            target="_blank"
            href="https://g.page/r/CfNl_2m6hN3gEBM/review"
            sx={{
              mr: 2,
              px: 4,
              py: 1,
              fontSize: "1.2rem",
              fontWeight: 700,
              textTransform: "none",
              borderColor: "#14192d",
              color: "white",
              borderRadius: "50px",
              backgroundColor: "var(--color-blue)",
              "&&:hover": {
                backgroundColor: "var(--color-orange)",
              },
            }}
          >
            Lämna ett omdöme
          </Button>
        </Typography>
      </Box>
    </StyledBox>
  );
};

export default Review;
